import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useState, type FC, type ReactNode, useEffect } from 'react';

import {
	MessageDirectionEnum,
	MessageTypeEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';
import { parseMarkdown } from '@heylog-app/shared/util';

import {
	StyledMessageBubble,
	StyledMessageTemplateButtons,
	StyledReactionBubble,
	StyledTemplateButton,
	StyledTemplateHeader,
} from './message-bubble.styles';
import { MessageImage } from '../message-image';
import { MessageVideo } from '../message-video';
import { MessageFile } from '../message-file';
import { MessageAudio } from '../message-audio';
import { MessageLocation } from '../message-reaction';

import type { MessageResInterface, Reaction } from '@heylog-app/shared/types';

type MessageBubbleProps = {
	message?: MessageResInterface;
	reaction?: Reaction;
	showTranslated?: boolean;
	borderRadius?: string;
	bgCustomColor?: boolean;
};

export const MessageBubble: FC<MessageBubbleProps> = ({
	message,
	reaction,
	showTranslated,
	borderRadius,
	bgCustomColor,
}) => {
	const [showSentLinkOnUi, setShowSentLinkOnUi] = useState(true);

	useEffect(() => {
		if (message?.template?.name === 'order_status_update_request') {
			setShowSentLinkOnUi(false);
		}
		if (message?.template?.name === 'universal_notification') {
			setShowSentLinkOnUi(false);
		}
	}, [message?.template?.name]);

	const getBgColor = (message?: MessageResInterface) => {
		if (
			message?.direction === MessageDirectionEnum.TO_CONTACT &&
			message?.transportType === MessagingTransportTypeEnum.VIBER
		) {
			return 'purple';
		}
		if (
			message?.direction === MessageDirectionEnum.FROM_CONTACT &&
			message?.transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP
		) {
			return 'green';
		}
		if (bgCustomColor) {
			return 'customGreen';
		}

		return 'grey';
	};

	const bgColor = getBgColor(message);

	const templateButtons = message?.template?.components?.find(
		(component) => component.type === 'BUTTONS',
	);

	const templateHeader = message?.template?.components?.find(
		(component) => component.type === 'HEADER',
	);

	const templateFooter = message?.template?.components?.find(
		(component) => component.type === 'FOOTER',
	);

	const translatedTemplateButtons = message?.translatedTemplate?.components?.find(
		(component) => component.type === 'BUTTONS',
	);

	const translatedTemplateHeader = message?.translatedTemplate?.components?.find(
		(component) => component.type === 'HEADER',
	);

	const translatedTemplateFooter = message?.translatedTemplate?.components?.find(
		(component) => component.type === 'FOOTER',
	);

	const { t } = useTranslation();

	if (!message) return null;

	return (
		<>
			<StyledMessageBubble
				data-test="message-bubble"
				$bgColor={bgColor}
				$hasTranslation={!!message?.translatedText}
				$borderRadius={borderRadius}
			>
				{message.messageType === MessageTypeEnum.AUDIO && (
					<MessageAudio
						attachment={message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.IMAGE && (
					<MessageImage
						attachment={message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.VIDEO && (
					<MessageVideo
						attachment={message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.DOCUMENT && (
					<MessageFile
						attachment={message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.LOCATION && message.location && (
					<MessageLocation location={message.location} />
				)}

				{showTranslated &&
				translatedTemplateHeader &&
				message.transportType !== MessagingTransportTypeEnum.VIBER &&
				message.messageType === MessageTypeEnum.WHATSAPP_TEMPLATE ? (
					<div data-test="translated-template-display-header">
						{translatedTemplateHeader && (
							<StyledTemplateHeader>{translatedTemplateHeader.text}</StyledTemplateHeader>
						)}
					</div>
				) : (
					templateHeader &&
					message.transportType !== MessagingTransportTypeEnum.VIBER && (
						<div data-test="template-display-header">
							<StyledTemplateHeader>{templateHeader.text}</StyledTemplateHeader>
						</div>
					)
				)}

				{message.text && (
					<ReactMarkdown
						children={parseMarkdown(
							showTranslated && message.translatedText
								? message.translatedText
								: message.text,
						)}
						components={{
							strong: ({ children }: { children: ReactNode }) => (
								<strong>{children}</strong>
							),
							em: ({ children }: { children: ReactNode }) => <em>{children}</em>,
							del: ({ children }: { children: ReactNode }) => <del>{children}</del>,
							code: ({ children }: { children: ReactNode }) => <code>{children}</code>,
							a: ({ children }: { children: ReactNode }) =>
								!showSentLinkOnUi ? (
									<span>{t('chats.link-sent')}</span>
								) : (
									<a href={children?.toLocaleString()} data-test="message-link">
										{children}
									</a>
								),
						}}
						remarkPlugins={[remarkGfm]}
					/>
				)}

				{showTranslated &&
				translatedTemplateFooter &&
				message.transportType !== MessagingTransportTypeEnum.VIBER &&
				message.messageType === MessageTypeEnum.WHATSAPP_TEMPLATE ? (
					<div>{translatedTemplateFooter.text}</div>
				) : (
					templateFooter &&
					message.transportType !== MessagingTransportTypeEnum.VIBER && (
						<div>{templateFooter.text}</div>
					)
				)}

				{reaction && reaction.emoji && (
					<StyledReactionBubble>{reaction.emoji}</StyledReactionBubble>
				)}
			</StyledMessageBubble>

			{message.messageType === MessageTypeEnum.WHATSAPP_TEMPLATE &&
			showTranslated &&
			message.transportType !== MessagingTransportTypeEnum.VIBER &&
			translatedTemplateButtons ? (
				<Tooltip
					title={t('chats.message.templateTooltip', {
						contactName:
							`${message?.contact?.firstName} ${message?.contact?.lastName}` || '',
					})}
					placement="bottom"
					arrow
				>
					<StyledMessageTemplateButtons>
						{translatedTemplateButtons?.buttons?.map((button, index) => {
							return (
								<StyledTemplateButton
									key={index}
									data-test="translated-template-display-button"
								>
									{button.text}
								</StyledTemplateButton>
							);
						})}
					</StyledMessageTemplateButtons>
				</Tooltip>
			) : (
				templateButtons &&
				message.transportType !== MessagingTransportTypeEnum.VIBER && (
					<Tooltip
						title={t('chats.message.templateTooltip', {
							contactName:
								`${message?.contact?.firstName || 'New'} ${
									message?.contact?.lastName || 'contact'
								}` || '',
						})}
						placement="bottom"
						arrow
					>
						<StyledMessageTemplateButtons>
							{templateButtons?.buttons?.map((button, index) => {
								return (
									<StyledTemplateButton key={index} data-test="template-display-button">
										{button.text}
									</StyledTemplateButton>
								);
							})}
						</StyledMessageTemplateButtons>
					</Tooltip>
				)
			)}
		</>
	);
};
