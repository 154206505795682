import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ButtonV2,
  FlashSnackbar,
  ROUTES,
  SidebarLayout,
  useSnackbar,
  useUser,
  useUserToUserActionsMessage,
} from '@heylog-app/frontend-lib/app';
import { Icon } from '@mdi/react';
import { StyledGoBackLink } from 'libs/frontend-lib/modules/shipments/src/pages/shipment/components/shipment-details.styles';
import { ButtonsGridContainer, StyledDetailsTitle } from './conversations.styles';
import { mdiArrowLeft } from '@mdi/js';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { NewConversationForm } from './new-conversation-form';
import { Nullable } from '@heylog-app/shared/types';

interface Contact {
  id: string;
  name: string;
  email: string;
  avatarUrl?: string;
}


export const NewConversation = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState<Nullable<string>>(null,
  );
  const [message, setMessage] = useState('');
  const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
  const { user } = useUser();
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const { sendUserToUserChat } = useUserToUserActionsMessage()
  const onSubmit = async () => {
    if (message) {
      if (selectedContacts?.length > 0) {
        const response = await sendUserToUserChat({
          message: message, orderId: selectedOrderId ? Number(selectedOrderId) : null,
          userIds: [
            ...selectedContacts?.map(item => Number(item.id)),
            Number(user?.id) // Append user.id
          ]
        });
        if (response.status === 200 || response.status === 201) {
          openSnackbar(
            'success'
            , "Created SuccessFully")
          navigate(generatePath(ROUTES.MESSAGES.OVERVIEW, {
            ...params,
          }),)
        } else {
          openSnackbar(
            'error'
            , "Error in sending Message")
        }
      } else {
        openSnackbar(
          'error'
          , "Please Select Contact.")
      }
    } else {
      openSnackbar(
        'error'
        , "Please Enter Message.")
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <SidebarLayout overflowAuto={true}>
        <Box
          width="95%"
          sx={{
            marginTop: '30px',
            marginLeft: '30px',
            paddingBottom: '20px',
          }}
        >
          <StyledGoBackLink to={generatePath(ROUTES.MESSAGES.OVERVIEW, params)}>
            <Icon path={mdiArrowLeft} size={1} />
            {t('messages.backNew')}
          </StyledGoBackLink>
          <Stack flexDirection="row" justifyContent="space-between">
            <StyledDetailsTitle>{t('contacts.newHeylogConvo')}</StyledDetailsTitle>
            <ButtonsGridContainer>
              <ButtonV2 $variant="outlined2" onClick={() => { navigate(generatePath(ROUTES.MESSAGES.OVERVIEW, params))}} >
                {t('actionLabels.cancel')}
              </ButtonV2>
              <ButtonV2 $variant="dark" onClick={onSubmit} style={{
                borderRadius: '4px',
              }}>
                {t('actionLabels.send')}
              </ButtonV2>
            </ButtonsGridContainer>
          </Stack>
          <NewConversationForm
            setSelectedOrderId={setSelectedOrderId}
            message={message}
            setMessage={setMessage}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
          />
        </Box>
      </SidebarLayout>
      <FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
    </Box>
  );
};
