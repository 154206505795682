
import { AddContactFormV2, ButtonV2, IconAttention, IconFire, IconHeylog, IconMessage, IconSMS2, IconWhatsApp2, ModalClose, ROUTES, SidebarSearch, useDebounce, useDialog, useMenu } from '@heylog-app/frontend-lib/app';
import { mdiArchiveArrowDownOutline, mdiArrowLeft, mdiFilterOutline, mdiMenuDown, mdiMessagePlusOutline, mdiPaperclipPlus, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Dialog, DialogTitle, InputAdornment, MenuItem, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState, type FC } from 'react';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StyledGoBackLink, StyledListItemText, StyledMenuButton, StyledParagraph, StyledTextField, StyledTitle } from './messages-header.styles';
import { useTranslation } from 'react-i18next';
import { ArchiveOutlined, SearchOutlined } from '@mui/icons-material';
import Shape from '../../images/Shape.png';

interface TabPanelProps {
	handleNewEmailClick?: () => void;
	handleHeylogClick?: () => void;
	setIsSearching?: any;
	handleArchiveAll?: () => void;
	setSearchParams?: any;
	setSearchTerm?: any;
	setSearchType?: any;
	disableArchiveButton?: any;
	tabsValue?: string;
}

export const MessageHeader: FC<TabPanelProps> = ({ handleNewEmailClick, handleHeylogClick, setIsSearching, handleArchiveAll, setSearchParams, setSearchTerm, disableArchiveButton, tabsValue }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const params = useParams();
	const handleNewMessageClick = () => {
		navigate(generatePath(ROUTES.CONTACTS.OVERVIEW, params));
	};
	const {
		showDialog: showNewContactDialog,
		openDialog: openNewContactDialog,
		closeDialog: closeNewContactDialog,
	} = useDialog();


	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
			setIsSearching(true);
		},
		[setSearchTerm],
	);
	const menuControl = useMenu();
	const handleNewContactSuccess = useCallback(() => {
		// openSnackbar('success', t('contacts.form.smsSent'));
		closeNewContactDialog();
	}, [closeNewContactDialog, t]);
	return (
		<>	<Stack gap={1}>
			<Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between">
				<Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
					<StyledTitle>{t("messages.conversations")}</StyledTitle>
				</Box>

				<Stack gap={2} direction="row" alignItems="center" justifyContent="flex-end" >

					<StyledTextField
						data-test="search-contacts-field"
						type={'text'}
						placeholder={t("messages.searchPlaceHolder")}
						onChange={handleSearch}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					// slotProps={{
					// 	input: {
					// 		startAdornment: <InputAdornment position="start">kg</InputAdornment>,
					// 	},
					// }}
					/>
					{/* <ButtonV2 onClick={() => { }} $variant="light2" $padding="8px">
						<Icon path={mdiFilterOutline} size={1.3} />
					</ButtonV2> */}
					<ButtonV2
						disabled={disableArchiveButton || tabsValue === "ARCHIVED"}
						onClick={() => {
							handleArchiveAll && handleArchiveAll()
						}} $variant="light2" $padding="11px" $height='48px'>
						<ArchiveOutlined style={{ fontSize: '23px' }} />
					</ButtonV2>
					<StyledMenuButton
						renderChildren={(onClose) => (
							<div >
								<StyledListItemText>
									<MenuItem onClick={() => {
										handleHeylogClick && handleHeylogClick()
									}}>
										<Stack direction="row" alignItems="center" gap={1}>
											<IconHeylog />
											{t('shipment.heylog')}
										</Stack>
									</MenuItem>
								</StyledListItemText>
								<StyledListItemText>
									<MenuItem onClick={() => {
										openNewContactDialog()
									}}>
										<Stack direction="row" alignItems="center" gap={1}>
											<IconWhatsApp2 />
											{t('shipment.whatsapp')}
										</Stack>
									</MenuItem>
								</StyledListItemText>
								<StyledListItemText>
									<MenuItem onClick={() => {
										openNewContactDialog()
									}}>
										<Stack direction="row" alignItems="center" gap={1}>
											<IconMessage />
											{t('shipment.sms')}
										</Stack>
									</MenuItem>
								</StyledListItemText>
								<StyledListItemText>
									<MenuItem onClick={() => {
										handleNewEmailClick && handleNewEmailClick();
										onClose();
									}}>
										<Stack direction="row" alignItems="center" gap={1}>
											<IconSMS2 />
											{t('shipment.email')}
										</Stack>
									</MenuItem>
								</StyledListItemText>
							</div>
						)}
						id="message-menu"
						control={menuControl}
						slotProps={{
							paper: {
								style: {
									position: 'absolute',
									left: '1335px !important',
									transform: 'none',
									boxShadow: "0px 0px 2px 0px rgba(34, 53, 72, 0.20),  0px 2px 4px 0px rgba(34, 53, 72, 0.20)",
									border: "1px solid #122CC5",
									borderRadius: "4px"
								},
							},
						}}
						button={
							<div>
								<ButtonV2
									$height='48px'
									$variant="dark"
									$minWidth="244px"
									startIcon={<Icon path={mdiMessagePlusOutline} size={1} />}
									sx={{
										border: menuControl.isMenuOpen ? '1px solid #122CC5' : '1px solid transparent',
										transition: 'border-color 0.2s ease-in-out',
									}}
									endIcon={
										<Box
											sx={{
												borderLeft: `1px solid rgba(255, 255, 255, 0.2)`,
												marginLeft: '12px',
												paddingLeft: '8px',
												marginRight: '-12px',
												display: 'box',
											}}
										>
											<Icon path={mdiMenuDown} size={1.3} />
										</Box>
									}
								>
									{t('messages.newConversation')}
								</ButtonV2>
							</div>
						}
					/>

				</Stack>
			</Stack>
		</Stack >
			<Dialog
				open={showNewContactDialog}
				onClose={closeNewContactDialog}
				fullWidth
				maxWidth="md"
			>
				<ModalClose closeModalFn={closeNewContactDialog} />
				<DialogTitle>{t('contacts.inviteNewContact')}</DialogTitle>
				<AddContactFormV2
					onSuccess={handleNewContactSuccess}
					onCancel={closeNewContactDialog}
				/>
			</Dialog>
		</>
	);
};
