import { Autocomplete, Chip } from "@mui/material";
import styled from "styled-components";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '0px #E1E4EA', // Border color on hover
        },
    },
    '& .MuiAutocomplete-inputRoot': {
        borderRadius: '4px',
        border: '1px solid  #E1E4EA',
        backgroundColor: '#f0f0f0',
        color: 'var(--White-600, #7C839E)', // Text color inside the input
    },
    '& .MuiAutocomplete-listbox': {
        backgroundColor: 'var(--White-200, #F5F8FE)', // Background color of the dropdown options
        color: 'var(--White-600, #7C839E)',
        '& .MuiAutocomplete-option': {
            '&:hover': {
                backgroundColor: 'var(--White-200, #F5F8FE)', // Hover state background
                color: 'var(--White-200, #F5F8FE)', // Hover state text color
            },
        },
    },
}));
export const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '4px',
    border: `0px`,
    backgroundColor: 'var(--White-300, #E1E4EA)',
    color: 'var(--Primary-Darker, #030B5E)',
    '& .MuiChip-deleteIcon': {
        color: '#7C839E',
    },
}));
export const LinkedOrderDiv = styled.div`
  color: var(--Primary-Darker, #030b5e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;