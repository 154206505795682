import Icon from "@mdi/react";
import styled from "styled-components";

import { STYLE_3, SIZE_MD, STYLE_2, SIZE_XS } from "../../../styles";
import { Chip } from "@mui/material";

export const StyledContactName = styled.div`
  ${STYLE_3};
  ${SIZE_MD};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledContactDetail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${STYLE_2};
  ${SIZE_XS};
  width: 166px;
  margin-top: 0.25rem;
`;

export const StyledContactLabel = styled.span`
  ${STYLE_2};
  ${SIZE_XS};
  margin-top: 0.25rem;
  color: var(--White-600, #7c839e);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

export const StyledContactDetailIcon = styled(Icon)`
  margin-right: 6px;
  margin-bottom: -2px;
`;
export const StyledLinkedOrderChip = styled(Chip)(({ theme }) => ({
  borderRadius: "4px",
  border: `0px`,
  backgroundColor: "var(--White-300, #E1E4EA)",
  color: "var(--Primary-Darker, #030B5E)",
  "& .MuiChip-deleteIcon": {
    color: "#7C839E",
  },
}));
