import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import { useState, type FC, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { archiveConversationsAPI, ButtonV2, EmailDialogActionEnum, FlashSnackbar, getUnreadSum, MessageContext, MessageDialogTypesEnum, ROUTES, useActiveUnread, useApiClientContext, useConversationV2Actions, useDebounce, useMessagesCount, useMessagesCountHeylog, useMessagesData, useSnackbar, useTableColumnsSettings } from '@heylog-app/frontend-lib/app';


import { GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { StyledTab } from './message-data-header.styled';
import { MessageHeader } from './messages-header/messages-header';
import { MessagesDataGrid } from './messages-data-grid';
import { CustomTabs, StyledUnreadCount } from './messages-data-table.styles';
import { ArchiveOutlined, DoneRounded, MenuOutlined } from '@mui/icons-material';

const searchTypeButtons = [
    { type: "", icon: <MenuOutlined style={{ fontSize: '23px' }} /> },
    { type: "ACTIVE", icon: <DoneRounded style={{ fontSize: '23px' }} /> },
    { type: "ARCHIVED", icon: <ArchiveOutlined style={{ fontSize: '23px' }} /> },
];

export const MessagesListTable: FC = () => {
    const [selectedConversations, setSelectedConversations] = useState<number[]>([])
    const [rowClick, setRowClick] = useState<GridRowParams>()
    const [paginationModel, setPaginationModel] = useState<any>({
        page: 1,
        pageSize: 10,
    })
    const [value, setValue] = useState("All");
    const [searchType, setSearchType] = useState<string | undefined>("ACTIVE");
    const topSectionRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const { apiClient } = useApiClientContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const navParams = useParams();
    const { workspaceId } = navParams;
    const apiRef = useGridApiRef();
    const { onOpenMessageDialog } = useContext(MessageContext);
    const [loader, setLoader] = useState<boolean>(false)
    const { toggleTodo } = useConversationV2Actions({
        id: null,
        workspaceId: workspaceId?.toString() || "",
    });
    const handleTodoChange = async (params: GridRowParams, value: boolean) => {
        setLoader(true)
        const res = await toggleTodo(Number(params?.id));
        if (res?.error) {
            setLoader(false)
            openSnackbar('error', res?.error)
        };
        updateConversationsV2()
        setLoader(false)

    };
    const [searchTerm, setSearchTerm] = useState<string>()
    const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);

    const { columns, rows, updateConversationsV2, pagination, isLoading } = useMessagesData([], handleTodoChange, paginationModel, debouncedSearchTerm, value, searchType);
    // const { FiltersComponent, activeFiltersCount, filteredRows } =
    //     useMessagesDataTableFilters(rows);

    // const { messagesCount } = useMessagesCount(
    //     workspaceId ? workspaceId : '-1',
    // );
    const { activeUnread } = useActiveUnread();
    const unreadCount = getUnreadSum(activeUnread);
    const hasContactsUnread = unreadCount > 0;
    const { messagesCount: heylogCount } = useMessagesCountHeylog(
        workspaceId ? workspaceId : '-1',
    );
    const allMessagesCount = unreadCount + heylogCount?.count || 0;

    const whatsappMessagesCount = unreadCount || 0;

    useEffect(() => {
        updateConversationsV2()
    }, [paginationModel?.pageNumber, paginationModel?.pageSize])
    const {
        adjustedColumns,
        handleColumnResize,
        handleColumnOrderChange,
        handleColumnVisibilityModelChange,
    } = useTableColumnsSettings({
        columns,
        apiRef,
        localStorageName: 'messages-data-table',
    });
    const handleRowClick = (params: GridRowParams) => {
        setRowClick(params)
    }
    const onChangePage = (props: any) => {
        setPaginationModel({ pageNumber: props.page + 1, pageSize: props.pageSize })
        setSearchParams({ pageNumber: props.page + 1, pageSize: props.pageSize }, { replace: true })
    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setSearchParams({ q: debouncedSearchTerm }, { replace: true });
        } else {
            setSearchParams({}, { replace: true });
        }
    }, [debouncedSearchTerm, setSearchParams]);
    const handleNewEmailClick = () => {
        if (onOpenMessageDialog)
            onOpenMessageDialog({
                type: MessageDialogTypesEnum.EMAIL,
                action: EmailDialogActionEnum.NEW,
                payload: {},
            });
    };
    const handleHeylogClick = () => {
        navigate(generatePath(ROUTES.MESSAGES.NEW, navParams));
    }


    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleRowSelectionChange = (records: string[]) => {
        setSelectedConversations((records?.map((record) => Number(record))))
    }
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
    const handleArchiveAll = async () => {
        if (!workspaceId && selectedConversations?.length <= 0) return;
        setLoader(true)
        await archiveConversationsAPI(apiClient, {
            data: {
                conversationIds: selectedConversations
            },
            workspaceId: workspaceId || "",
        })
            .then(() => {
                setLoader(false)
                openSnackbar('success', t('messages.archive'));
                updateConversationsV2()
            })
            .catch((e) => {
                setLoader(false)

            })
    }
    return (

        <Grid wrap="nowrap" columns={{ xs: 12 }} sx={{ height: '100%', width: "100%", padding: "16px" }}>
            <Stack direction="column" sx={{ height: '100%', width: "100%" }} gap={1}>
                <div ref={topSectionRef}>
                    <Stack direction="column">
                        <MessageHeader
                            handleHeylogClick={handleHeylogClick}
                            handleNewEmailClick={handleNewEmailClick}
                            setIsSearching={setIsSearching}
                            handleArchiveAll={handleArchiveAll}
                            setSearchTerm={setSearchTerm}
                            setSearchParams={setSearchParams}
                            tabsValue={searchType}
                            disableArchiveButton={selectedConversations?.length === 0 ? true : false}
                        />
                        <Stack direction={"row"} justifyContent={"space-between"}>

                            <CustomTabs
                                value={value}
                                onChange={handleChange}
                                sx={{ width: '100%' }}
                            >
                                <StyledTab label={
                                    <>
                                        <Grid display={'flex'} gap={2}>
                                            {t('messages.all')}
                                            {allMessagesCount > 0 && (
                                                <StyledUnreadCount style={{ backgroundColor: "#122CC5" }}>
                                                    {allMessagesCount > 99 ? '99+' : allMessagesCount}
                                                </StyledUnreadCount>
                                            )}
                                        </Grid>
                                    </>
                                } value={"All"} />
                                <StyledTab label={
                                    <Grid display="flex" gap={2}>
                                        {t('messages.heylog')}
                                        {heylogCount?.count > 0 && (
                                            <StyledUnreadCount>
                                                {heylogCount?.count > 99 ? '99+' : heylogCount?.count}
                                            </StyledUnreadCount>
                                        )}
                                    </Grid>
                                } value={"Heylog"} />
                                <StyledTab label={
                                    <>
                                        <Grid display="flex" gap={2}>
                                            {t('messages.whatsapp')}
                                            {whatsappMessagesCount > 0 && (
                                                <StyledUnreadCount>
                                                    {whatsappMessagesCount > 99 ? '99+' : whatsappMessagesCount}
                                                </StyledUnreadCount>
                                            )}

                                        </Grid>
                                    </>
                                } value={"Whatsapp"} />
                                <StyledTab label={t('messages.email')} value={"Email"} />
                            </CustomTabs>

                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                <Box sx={{ backgroundColor: '#F5F8FE', display: 'inline-flex' }}>
                                    {searchTypeButtons.map(({ type, icon }) => (
                                        <ButtonV2
                                            key={type}
                                            onClick={() => setSearchType(type)}
                                            $showActive={searchType === type}
                                            $variant="light3"
                                            $padding="11px"
                                            $height="45px"
                                            $width="45px"
                                        >
                                            {icon}
                                        </ButtonV2>
                                    ))}
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        height: '100%',
                    }}
                >
                    <MessagesDataGrid
                        rows={rows}
                        columns={adjustedColumns}
                        handleRowClicks={handleRowClick}
                        apiRef={apiRef}
                        rowCount={pagination?.totalRows}
                        loader={isLoading}
                        handleRowSelectionChange={handleRowSelectionChange}
                        checkboxSelection={searchType === "ARCHIVED" ? false : true}
                        onPageChange={onChangePage} // Callback to fetch new data for the given page
                        handleColumnOrderChange={handleColumnOrderChange}
                        handleColumnResize={handleColumnResize}
                        handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                        activeTab={value}
                    />
                </Stack>
            </Stack>
            <FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

        </Grid>
    );
};
