import { StyledSnackbar, StyledAlert, StyledSnackbarV2, StyledAlertV2 } from './flash-snackbar.styles';

import type { UseSnackbarReturnType } from '../../types';
import type { FC } from 'react';

export const FlashSnackbar: FC<{
	controls: UseSnackbarReturnType;
	handleClose?: () => void;
	anchorOrigin?: { vertical: 'bottom' | 'top'; horizontal: 'center' | 'left' | 'right' };
}> = ({ controls, handleClose, anchorOrigin }) => {
	const [state, , close] = controls;

	const onClose = () => {
		if (state.autoClose) {
			close();
		}
		if (handleClose) {
			handleClose();
		}
	};
	const vertical = anchorOrigin?.vertical || state.anchorOrigin?.vertical || 'top';
	const horizontal = anchorOrigin?.horizontal || state.anchorOrigin?.horizontal || 'right';

	return (
		<StyledSnackbar
			open={state.open}
			autoHideDuration={state.autoCloseDuration}
			onClose={onClose}
			anchorOrigin={{
				vertical: vertical,
				horizontal: horizontal,
			}}
			data-test="styled-snackbar"
			data-test-severity={state.severity}
		>
			<StyledAlert onClose={onClose} severity={state.severity} sx={{ width: '100%' }}>
				{state.message}
			</StyledAlert>
		</StyledSnackbar>
	);
};


export const FlashSnackbarV2: FC<{
	controls: UseSnackbarReturnType;
	handleClose?: () => void;
	anchorOrigin?: { vertical: 'bottom' | 'top'; horizontal: 'center' | 'left' | 'right' };
}> = ({ controls, handleClose, anchorOrigin }) => {
	const [state, , close] = controls;

	const onClose = () => {
		if (state.autoClose) {
			close();
		}
		if (handleClose) {
			handleClose();
		}
	};

	return (
		<StyledSnackbarV2
			open={state.open}
			autoHideDuration={state.autoCloseDuration}
			onClose={onClose}
			anchorOrigin={{
				vertical: anchorOrigin?.vertical || 'top',
				horizontal: anchorOrigin?.horizontal || 'center',
			}}
			data-test="styled-snackbar"
			data-test-severity={state.severity}
		>
			<StyledAlertV2 severity={state.severity} sx={{ width: '100%' }} >
				{state.message}
			</StyledAlertV2>
		</StyledSnackbarV2>
	);
};