import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useState, type FC, type ReactNode, useEffect } from 'react';

import {
	MessageDirectionEnum,
	MessageTypeEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';
import { parseMarkdown } from '@heylog-app/shared/util';

import {
	StyledMessageBubble,
	StyledMessageTemplateButtons,
	StyledReactionBubble,
	StyledTemplateButton,
	StyledTemplateHeader,
} from './message-bubble.styles';
import { MessageImage } from '../message-image';
import { MessageVideo } from '../message-video';
import { MessageFile } from '../message-file';
import { MessageAudio } from '../message-audio';
import { MessageLocation } from '../message-reaction';

import type { MessageResInterface, Reaction } from '@heylog-app/shared/types';

type MessageBubbleProps = {
	message?: MessageResInterface;
	reaction?: Reaction;
	showTranslated?: boolean;
};

export const MessageBubbleV2: FC<MessageBubbleProps> = ({
	message,
	reaction,
	showTranslated,
}) => {
	const [showSentLinkOnUi, setShowSentLinkOnUi] = useState(true);



	const getBgColor = (message?: MessageResInterface) => {
		if (
			message?.direction === MessageDirectionEnum.TO_CONTACT &&
			message?.type === MessagingTransportTypeEnum.HEYLOG
		) {
			return 'purple';
		}
		if (
			message?.direction === MessageDirectionEnum.FROM_CONTACT &&
			message?.type === MessagingTransportTypeEnum.HEYLOG
		) {
			return 'green';
		}

		return 'grey';
	};
	const bgColor = getBgColor(message);



	const { t } = useTranslation();

	if (!message) return null;

	return (
		<>
			{console.log('message', message)}

			<StyledMessageBubble
				data-test="message-bubble"
				$bgColor={bgColor}
				$hasTranslation={false}
			>
				{message.messageType === MessageTypeEnum.AUDIO && (
					<MessageAudio
						attachment={message && message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.IMAGE && (
					<MessageImage
						attachment={message && message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.VIDEO && (
					<MessageVideo
						attachment={message && message.attachments ? message.attachments[0] : undefined}
					/>
				)}
				{message.messageType === MessageTypeEnum.DOCUMENT && (
					<MessageFile
						attachment={message && message?.attachments ? message?.attachments?.[0] : undefined}
					/>
				)}



				{message.text && (
					<ReactMarkdown
						children={parseMarkdown(
							message.text,
						)}
						components={{
							strong: ({ children }: { children: ReactNode }) => (
								<strong>{children}</strong>
							),
							em: ({ children }: { children: ReactNode }) => <em>{children}</em>,
							del: ({ children }: { children: ReactNode }) => <del>{children}</del>,
							code: ({ children }: { children: ReactNode }) => <code>{children}</code>,
							a: ({ children }: { children: ReactNode }) =>
								!showSentLinkOnUi ? (
									<span>{t('chats.link-sent')}</span>
								) : (
									<a href={children?.toLocaleString()} data-test="message-link">
										{children}
									</a>
								),
						}}
						remarkPlugins={[remarkGfm]}
					/>
				)}



				{reaction && reaction.emoji && (
					<StyledReactionBubble>{reaction.emoji}</StyledReactionBubble>
				)}
			</StyledMessageBubble>
		</>
	);
};
