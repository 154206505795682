import styled from 'styled-components';

interface ModalCloseWrapperProps {
	top?: string;
	right?: string;
}


export const ModalCloseWrapper = styled.div<ModalCloseWrapperProps>`
	position: absolute;
	z-index: 99;
	top: ${({ top }) => top || '8px'};
    right: ${({ right }) => right || '8px'};
`;
