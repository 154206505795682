import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';

import { StyledDivWrapper } from './email-editor.styles';

import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import type { AttachmentResInterface } from '@heylog-app/shared/types';

interface EmailEditorProps {
	readOnly?: boolean;
	initialData?: string;
	attachments?: AttachmentResInterface[];
	hideToolbar?: boolean;
	editorMinHeight?: number;
	onReady?: () => void;
	hideBorder?: boolean;
}

const domParser = new DOMParser();

const prepareConfig = (initConfig: EditorConfig, payload: { hideToolbar?: boolean }) => {
	const hiddenToolbarObj = payload.hideToolbar
		? {
				toolbar: {
					items: [],
				},
		  }
		: {};
	const config: EditorConfig = { ...initConfig, ...hiddenToolbarObj };

	return config;
};
export const EmailEditor = forwardRef(
	(
		{
			initialData = '',
			readOnly,
			editorMinHeight,
			hideToolbar,
			hideBorder,
			attachments = [],
			onReady,
		}: EmailEditorProps,
		ref,
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const editorRef = useRef<any>();

		const config = prepareConfig({}, { hideToolbar });

		useImperativeHandle(ref, () => ({
			setHtml: (data: string) => {
				editorRef.current?.editor?.setData(data);
			},
			getHtml: () => {
				return editorRef.current?.editor.getData();
			},
			getText: () => {
				// uses a ckeditor5-clipboard internal function to convert the view to plain text (https://github.com/ckeditor/ckeditor5/issues/8121)
				return viewToPlainText(editorRef.current?.editor.editing.view.document.getRoot());
			},
		}));

		// finds img elements and replaces inline image references with the GCP url
		function inlineImageAttachments(data: string): string {
			const doc = domParser.parseFromString(data, 'text/html');
			const inlineImageElements: NodeListOf<HTMLImageElement> =
				doc.querySelectorAll('img[src^="cid:"]');
			inlineImageElements.forEach((img) => {
				img.src =
					attachments.find((attachment) => {
						return attachment.contentId === img.src.replace('cid:', '');
					})?.publicUrl ?? img.src;
			});

			return doc.documentElement.outerHTML;
		}

		return (
			<StyledDivWrapper $editorMinHeight={editorMinHeight} $withBorders={hideBorder}>
				<CKEditor
					ref={editorRef}
					config={config}
					editor={ClassicEditor}
					data={inlineImageAttachments(initialData)}
					onReady={(editor) => {
						if (readOnly) {
							// enable read only mode for all components
							editor.enableReadOnlyMode('');
						}
						onReady?.();
					}}
				/>
			</StyledDivWrapper>
		);
	},
);

export default EmailEditor;
