import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const WorkspaceEventsEnum = mkenum({
	CONTACT_UPDATED: 'contact:updated',
	MESSAGE_SENT: 'message:sent',
	MESSAGE_RECEIVED: 'message:received',
	MESSAGE_STATUS_UPDATED: 'message:status:updated',
	MESSAGE_UPDATED: 'message:updated',
	ENTITY_EVENT_ADDED: 'entity-event:added',
	USER_TO_USER_COMMENT: 'comment:added',
	ORDER_STATUS_UPDATED: "order-status:updated",
	TASK_STATUS_UPDATED: "task-status:updated",
	MESSAGE_USER_TO_USER: 'message-user-to-user:sent',
	MESSAGE_USER_TO_USER_READ: 'message-user-to-user:read'
});

export type WorkspaceEventsType = TypeFromPEnum<typeof WorkspaceEventsEnum>;
