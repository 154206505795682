import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import { GridOverlay } from "@mui/x-data-grid-pro";

export const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  gap: "10px",

  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));

export const OverLayText = styled(Box)`
  color: var(--Primary-Darker, #030b5e);
  text-align: center;
  margin-top: 18px;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
