import styled from "styled-components";

import { colors, SIZE_XS, SIZE_SM, STYLE_1 } from "../../../styles";

type StyledMessageBubbleProps = {
  $borderRadius?: string;
  $bgColor: "grey" | "green" | "purple" | "customGreen";
  $hasTranslation: boolean;
};

export const StyledMessageBubble = styled.div<StyledMessageBubbleProps>`
  position: relative;
  ${STYLE_1};
  font-size: 0.85rem;
  background-color: ${({ theme }) => theme.palette.greyblue[225]};

  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0 0 0;
  border-radius: ${({ $borderRadius }) => $borderRadius || "1rem"};
  white-space: pre-line;
  line-break: anywhere;

  ${({ theme, $bgColor }) =>
    $bgColor === "green" && `background-color: ${theme.palette.green.main};`}
  ${({ theme, $bgColor }) =>
    $bgColor === "purple" && `background-color: ${theme.palette.purple.main};`}
  ${({ theme, $bgColor }) =>
    $bgColor === "customGreen" &&
    `background-color: var(--Success-Light, #D9F9DA);`}

	p {
    margin: 0;
  }
  color: var(--Primary-Darker, #030b5e);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
`;

export const StyledReactionBubble = styled.div`
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${SIZE_XS};
  height: 26px;
  width: 26px;
  line-height: 0.66rem;
  padding-top: 2px;
  bottom: -24px;
  left: 9px;
  border-radius: 50%;
  border: 1px solid ${colors.greyblue[205]};
  background-color: white;
`;

export const StyledTemplateHeader = styled.div`
  font-weight: 600;
  padding-bottom: 8px;
`;

export const StyledMessageTemplateButtons = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 4px;
`;

export const StyledTemplateButton = styled.div`
  text-align: center;
  border-radius: 1rem;
  padding: 4px 8px;
  color: ${colors.greyblue[205]};
  border: 1px solid ${colors.greyblue[205]};
  width: 100%;
  ${SIZE_SM};
`;
