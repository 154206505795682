import { Box, Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	StyledDataGridPro,
	StyledGridPagination,
	StyledGridToolbarColumnsButton,
	StyledTitle,
} from './table-v2.styles';

import type { FC } from 'react';
import type {
	DataGridProProps,
	GridColDef,
	GridColumnResizeParams,
	GridRowParams,
	GridRowsProp,
} from '@mui/x-data-grid-pro';

export interface TableV2PropsInterface extends DataGridProProps {
	rows: GridRowsProp;
	columns: GridColDef[];
	withColumnSettings?: boolean;
	ToolbarContent?: JSX.Element;
	title?: string;
	handleRowClick?: (params: GridRowParams) => void;
	handleColumnResize?: (params: GridColumnResizeParams) => void;
	handleColumnOrderChange?: () => void;
	withPaginationSettings?: boolean;
	checkboxSelection?: boolean;
	handleRowSelectionChange?: (records: any) => void
	onPageChange?: (page: any) => void; // Callback to fetch new data for the given page
	rowCount?: number;
	pagination?: boolean;
}

export const TableV2: FC<TableV2PropsInterface> = ({
	rows,
	columns,
	withColumnSettings,
	ToolbarContent,
	title = '',
	slots,
	handleRowClick,
	handleColumnOrderChange,
	handleColumnResize,
	withPaginationSettings,
	checkboxSelection,
	handleRowSelectionChange,
	onPageChange,
	rowCount,
	pagination = false,
	...props
}) => {
	const [page, setPage] = useState(0);
	const hasToolbar = !!title || !!ToolbarContent;
	const { t } = useTranslation();
	const [paginationModel, setPaginationModel] = useState<any>({
		page: 0,
		pageSize: 10,
	})
	// const handlePageChange = (
	// 	_: any,
	// 	newPage: number,
	// ) => {
	// 	console.log(newPage);
	// 	setPage(newPage);
	// };
	useEffect(() => {
		if (onPageChange) onPageChange(paginationModel);

	}, [paginationModel])
	// const CustomFooter = useCallback(() => {
	// 	return (
	// 		<GridToolbarContainer>
	// 			<Stack
	// 				direction="row"
	// 				justifyContent="space-between"
	// 				sx={{ width: '100%', paddingBlock: '12px' }}
	// 			>
	// 				{/* we dont need the filter button since we introduce a custom filter? */}
	// 				{/* <GridToolbarFilterButton /> */}
	// 				<Stack direction="row">
	// 					{withColumnSettings && <StyledGridToolbarColumnsButton />}
	// 				</Stack>

	// 				{/* TODO: Server pagination */}
	// 				{/* {<StyledGridPagination
	// 					onPageChange={handlePageChange}
	// 					page={page}
	// 					labelRowsPerPage="Per page:"
	// 					count={rowCount}
	// 				/>} */}
	// 			</Stack>
	// 		</GridToolbarContainer>
	// 	);
	// }, [withColumnSettings]);

	const Toolbar = () => (
		<Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
			<StyledTitle>{t(title)}</StyledTitle>
			<Box marginLeft="auto">{ToolbarContent}</Box>
		</Box>
	);

	return (
		<Box sx={{ height: '100%' }}>
			<StyledDataGridPro
				sx={{ height: '100%' }}
				rowCount={rowCount || rows?.length}
				pagination={pagination}
				paginationMode="server"
				rows={rows || []}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				onColumnResize={handleColumnResize}
				onColumnOrderChange={handleColumnOrderChange}
				slots={{
					// 	footer: CustomFooter,
						toolbar: hasToolbar ? Toolbar : undefined,
					...slots,
					noRowsOverlay: slots?.noResultsOverlay,
				}}
				onRowClick={handleRowClick}
				columns={columns}
				columnHeaderHeight={82}
				rowHeight={70}
				checkboxSelection={checkboxSelection}
				onRowSelectionModelChange={handleRowSelectionChange}

				{...props}
			/>
		</Box>
	);
};
