import useSWR, { useSWRConfig } from 'swr';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { getAttachmentsKey, getFetcher } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';


// export const useAttachments = () => {
// 	// const ability = useAbility(AbilityContext);
// 	const { apiClient } = useApiClientContext();
// 	const { workspaceId, shipmentId } = useParams();

// 	const { data, error, isValidating, isLoading } = useSWR<FullAttachmentResInterface[]>(
// 		// ability.can(Actions.READ, 'Conversation') ? contactsKey : null,
// 		workspaceId ? getAttachmentsKey(workspaceId, shipmentId) : null,
// 		getFetcher(apiClient),
// 	);

// 	return {
// 		attachments: data,
// 		isLoading,
// 		isError: error,
// 		isValidating,
// 	};
// };

export const useUserToUserActionsMessage = () => {
	const { apiClient } = useApiClientContext();

	const { workspaceId } = useParams();

	const sendUserToUserChat = useCallback(
		async ({ userIds, message, orderId }: {
			"userIds": number[],
			"message": string,
			"orderId"?: number | undefined | null
		}) => {
			const data = { userIds, message, orderId };
			let res = await apiClient.post(
				`/workspaces/${workspaceId}/conversations-v2/user-to-user/group`,
				data,
			);
			return res;
		},
		[apiClient, workspaceId],
	);



	return {
		sendUserToUserChat,
	};
};
