import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { Conversation, useApiClientContext, useMessagesReadHeylog, useWsChannelEvent } from '@heylog-app/frontend-lib/app';
import { UserOrderStatusEnum, WorkspaceEventsEnum } from '@heylog-app/shared/types';

import { useContactContext } from './contacts';

import type { FC } from 'react';
import { ConversationV3Heylog } from 'libs/frontend-lib/app/src/components/conversation/conversationV3Heylog';
import { useContactContextV2 } from './contactsv3';
import { AxiosInstance } from 'axios';
import { getReadMessagesKeyHeylog } from 'libs/frontend-lib/app/src/util/get-conversationv2-key';


export const ContactConversationPageHeylog: FC = () => {
	const { workspaceId, conversationId } = useParams();
	const { currentConvo } = useContactContextV2();
	const { apiClient } = useApiClientContext();
	const { isLoading, refetch } = useMessagesReadHeylog(workspaceId, conversationId || "")

	return (
		<Box sx={{ height: '100%', overflow: 'hidden' }}>
			<ConversationV3Heylog
				shouldFocusOnRender={false}
				textareaWrapperStyles={{
					position: "sticky",
					bottom: "0",
					backgroundColor: "white",
				}}
				currentConvo={currentConvo}

			/>
		</Box>
	);
};
