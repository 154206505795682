import Icon from '@mdi/react';
import React, { useCallback, useState, type FC, useEffect } from 'react';
import {
	InfoWindow,
	Map,
	Marker,
	useMap,
	useMapsLibrary,
	useMarkerRef,
} from '@vis.gl/react-google-maps';
import { mdiTruckDeliveryOutline } from '@mdi/js';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	getFormattedEta,
	TaskDetailsDialog,
	useDialog,
	IconUntrack,
} from '@heylog-app/frontend-lib/app';
import { OrderTaskTypeEnum } from '@heylog-app/shared/types';
import SmallTruck from '../../images/truck_small.png'

import {
	StyledModalHeader,
	StyledModalLabel,
	StatusLabel,
	StyledDivLink,
	InfoWindowHeader,
	InfoWindowBody,
	StyledTypeLabel,
	StyledCompanyTextBlock,
	StyledBlock,
} from '../shipment-details.styles';

import type { OrderTaskResInterface } from '@heylog-app/shared/types';
import type { ShipmentMapProps } from '../interfaces/orderPropsInterface';

interface MakerInfo {
	lat: number;
	lng: number;
	index: number;
}

const createSVGIcon = (number: string, color: string) => {
	const svg = `
     <svg width="40" height="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" fill="${color}"/>
      <text x="12" y="12" text-anchor="middle" font-size="10" font-weight="bold" fill="#ffffff">${number}</text>
    </svg>`;
	return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};

export const ShipmentMap: FC<ShipmentMapProps> = ({
	center,
	completedTasks,
	currentTasks,
	deleteTask,
	liveLocationData,
	order,
	setStatusChanged,
	tasks,
	warningTasks,
	problemTasks,
}) => {
	const [markerRef] = useMarkerRef();
	const { t } = useTranslation();
	const [iconVariables, setIconVariables] = useState<{ url: string }[]>([]);
	const [selectedMarker, setSelectedMarker] = useState<MakerInfo | null>(null);
	const [markerInfo, setMarkerInfo] = useState<OrderTaskResInterface | null>(null);
	const [markerIndex, setMarkerIndex] = useState<number>(0);
	const [number, setNumber] = useState(0);
	const [dialogData, setDialogData] = useState<OrderTaskResInterface | null>(null);
	const {
		showDialog: showTaskDetailsDialog,
		openDialog: openTaskDetailsDialog,
		closeDialog: closeTaskDetailsDialog,
	} = useDialog();

	const onMarkerClick = useCallback(
		(marker: MakerInfo, step: OrderTaskResInterface, index: number) => {
			setSelectedMarker(marker);
			setMarkerInfo(step);
			setMarkerIndex(index);
		},
		[],
	);

	const onInfoWindowCloseClick = useCallback(() => {
		setSelectedMarker(null);
	}, []);

	const checkMarkerColor = (stepIndex: number) => {
		if (completedTasks.includes(stepIndex)) {
			return '#1fff1d';
		} else if (currentTasks.includes(stepIndex)) {
			return '#0000FF';
		} else if (warningTasks.includes(stepIndex)) {
			return '#FBD4B4';
		} else if (problemTasks?.includes(stepIndex)) {
			return 'red';
		} else {
			return '#9ba0b3';
		}
	};

	useEffect(() => {
		if (tasks.length > 0) {
			const updatedIconVariables = tasks.map(
				(task: OrderTaskResInterface, index: number) => ({
					url: createSVGIcon((index + 1).toString(), checkMarkerColor(index)),
				}),
			);
			setIconVariables(updatedIconVariables);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tasks, completedTasks, currentTasks, warningTasks]);

	const map = useMap();
	const maps = useMapsLibrary('maps');
	if (!maps) {
		return null;
	}

	const path = new maps.Polyline({
		path: liveLocationData,
		geodesic: true,
		strokeColor: '#FF0000',
		strokeOpacity: 1.0,
		strokeWeight: 2,
	});

	path.setMap(map);

	const styles = [
		{
			featureType: 'administrative.locality',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	];

	const openDialog = (data: OrderTaskResInterface, number: number) => {
		setDialogData(data);
		setNumber(number);
		openTaskDetailsDialog();
	};

	const deleteTaskAndCloseMapInfoWindow = () => {
		deleteTask(dialogData?.id);
		onInfoWindowCloseClick();
	};

	return (
		<Map
			zoom={10}
			center={center}
			gestureHandling={'greedy'}
			mapId={'739af084373f96fe'}
			streetViewControl={false}
			zoomControl={false}
			mapTypeControl={false}
			styles={styles}
		>
			{liveLocationData.length > 0 && (
				<>
					<Marker
						position={liveLocationData[0]}
						icon={{
							url: SmallTruck,
							scaledSize: new google.maps.Size(40, 40),
						}}
					/>
					<Marker
						position={liveLocationData[liveLocationData.length - 1]}
						icon={{
							url: SmallTruck,
							scaledSize: new google.maps.Size(40, 40),
						}}
					/>
				</>
			)}
			{tasks.map(
				(task: OrderTaskResInterface, index: number) =>
					task.latitude &&
					task.longitude && (
						<Marker
							ref={markerRef}
							position={{ lat: task.latitude, lng: task.longitude }}
							icon={iconVariables[index]}
							onClick={() =>
								onMarkerClick(
									{
										lat: task?.latitude as number,
										lng: task?.longitude as number,
										index,
									},
									task,
									index,
								)
							}
						/>
					),
			)}
			{selectedMarker && (
				<InfoWindow position={selectedMarker} onCloseClick={onInfoWindowCloseClick}>
					<InfoWindowHeader>
						{t('orders.taskDetailsDialog.title', { number: markerIndex + 1 })}
					</InfoWindowHeader>
					<InfoWindowBody>
						<Stack direction="row" justifyContent="space-between">
							<StyledModalHeader>
								{markerInfo?.type === OrderTaskTypeEnum.LOADING ? (
									<Icon path={mdiTruckDeliveryOutline} size={1} />
								) : (
									<IconUntrack />
								)}
								<StyledTypeLabel>
									{markerInfo?.type === OrderTaskTypeEnum.LOADING
										? t('orders.tasks.type.loading')
										: t('orders.tasks.type.unloading')}
								</StyledTypeLabel>
							</StyledModalHeader>
							{completedTasks.includes(markerIndex) ? (
								<StatusLabel completed={true} active={false}>
									{t('orders.tasks.status.done')}
								</StatusLabel>
							) : currentTasks.includes(markerIndex) ? (
								<StatusLabel completed={false} active={true}>
									{t('orders.tasks.status.in_progress')}
								</StatusLabel>
							) : warningTasks.includes(markerIndex) ? (
								<StatusLabel completed={true} active={false} cancelled={true}>
									{t('orders.tasks.status.cancelled')}
								</StatusLabel>
							) : problemTasks?.includes(markerIndex) ? (
								<StatusLabel completed={false} active={false} cancelled={false} problem={true}>
									{t('orders.tasks.status.problem')}
								</StatusLabel>
							) : (
								<StatusLabel completed={false} active={false}>
									{t('orders.tasks.status.pending')}
								</StatusLabel>
							)}
						</Stack>
						<Stack>
							<StyledModalLabel>{getFormattedEta(Date())}</StyledModalLabel>
						</Stack>
						<StyledBlock>
							<StyledModalLabel>{t('orders.details.company')}:</StyledModalLabel>
							<StyledCompanyTextBlock>{markerInfo?.company}</StyledCompanyTextBlock>
						</StyledBlock>
						<Stack>{markerInfo?.location}</Stack>
						<Stack>
							<StyledBlock>
								<StyledModalLabel>{t('orders.details.reference')}:</StyledModalLabel>
								{order?.refNumber}
							</StyledBlock>
						</Stack>
						<Stack sx={{ marginTop: '5px' }}>
							<StyledDivLink
								onClick={() => markerInfo && openDialog(markerInfo, markerIndex + 1)}
							>
								{t('orders.taskDetailsDialog.openDialog')}
							</StyledDivLink>
						</Stack>
					</InfoWindowBody>
				</InfoWindow>
			)}
			{dialogData && (
				<TaskDetailsDialog
					contacts={order?.contacts}
					customer={order?.customer ? order.customer : ''}
					dataTest="task-details-dialog"
					deleteTask={deleteTaskAndCloseMapInfoWindow}
					dialogTitle={t('orders.taskDetailsDialog.title', { number })}
					isOpen={showTaskDetailsDialog}
					onClose={closeTaskDetailsDialog}
					orderTask={dialogData}
					setStatusChanged={setStatusChanged}
				/>
			)}{' '}
		</Map>
	);
};
