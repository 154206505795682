import { useCallback, useEffect, useRef, useState } from 'react';
import {
	Chip,
	Dialog,
	DialogTitle,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tooltip,
} from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mdiAccount, mdiAccountRemove, mdiPencil, mdiTruck } from '@mdi/js';
import Icon from '@mdi/react';

import { ContactRoleEnum, MessagingTransportTypeEnum } from '@heylog-app/shared/types';
import { getRoleIcon } from '@heylog-app/shared/util';

import { colors } from '../../../styles/theme/colors';
import {
	StyledContactDetail,
	StyledContactLabel,
	StyledContactName,
	StyledContactDetailIcon,
	StyledLinkedOrderChip,
} from './contact-list-item.styles';
import { SidebarListItem } from '../../sidebar';
import { ButtonMenu, ContactName, ConversationAvatar, ConversationAvatarV2, ModalClose } from '../../ui';
import { useApiClientContext, useDialog, useMenu } from '../../../hooks';
import { ROUTES, disconnectContactFromOrderAPI } from '../../../util';
import { EditContactForm } from '../../forms/edit-contact-form/edit-contact-form';

import type { FullContactResInterface } from '@heylog-app/shared/types';
import type { ListItemProps } from '@mui/material';
import type { FC } from 'react';

type ContactListItemProps = Omit<ListItemProps, 'onClick'> & {
	contact: FullContactResInterface;
	unreadCount: number;
	onSelectContact: (contact: FullContactResInterface) => void;
};

const StyledChip = styled(Chip)`
	margin-left: 5px;
	border-radius: 4px;
	background-color: ${colors.error.main};
	color: ${colors.error.light};
`;

export const ContactListItemV2: FC<ContactListItemProps> = ({
	contact,
	unreadCount,
	onSelectContact,
	...props
}) => {
	const { apiClient } = useApiClientContext();
	const { contactId = '', orderId = '', workspaceId = '' } = useParams();
	const { t } = useTranslation();


	const { id, company } = contact;
	console.log('contact', contact)
	const isActive = contactId === id?.toString();


	const handleItemClick = useCallback(() => {
		onSelectContact(contact);
	}, [contact, onSelectContact]);

	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!textElementRef.current) return;
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
		);
	}, [contact?.chatName]);
	return (
		<SidebarListItem
			{...props}
			onClick={handleItemClick}
			active={isActive}

		>
			<ListItemAvatar>
				<ConversationAvatarV2
					contact={contact}
					src=""
					transportType={MessagingTransportTypeEnum.HEYLOG}
				/>
			</ListItemAvatar>
			<ListItemText>
				<StyledContactName>
					<Tooltip title={contact?.chatName || "New Contact"} disableHoverListener={!isOverflowed}>
						<StyledContactName ref={textElementRef}>{contact?.chatName || "New Contact"}</StyledContactName>
					</Tooltip>
				</StyledContactName>

				<StyledContactDetail>
					<StyledContactLabel>{t(`messages.routeOptimizationPlan`)}</StyledContactLabel>
					{contact?.orderReference && <StyledLinkedOrderChip label={contact?.orderReference} />}
					{company}
				</StyledContactDetail>
			</ListItemText>
			{!!unreadCount && <StyledChip label={unreadCount} size={'small'} />}
		</SidebarListItem>
	);
};
