
import { ButtonV2 } from '@heylog-app/frontend-lib/app';
import { Box, InputAdornment, Stack } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledParagraph, StyledTextField, StyledTitle } from './header.styles';
import { useTranslation } from 'react-i18next';
import { ArchiveOutlined, MenuOutlined, PersonAddAltOutlined, SearchOutlined, DoneRounded, AccessTimeRounded } from '@mui/icons-material';

type StatusType = 'ARCHIVED' | 'ALL' | 'PENDING_ONBOARDING' | 'ACTIVE';
interface HeaderProps {
    setStatus: Dispatch<SetStateAction<StatusType>>;
    setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
    headerTitle: string;
    newContactLabel: string;
    searchPlaceholder: string;
    handleNewContactClick: () => void;
    handleSearchCallback: (searchTerm: string) => void;
    setSearchParams: any;
    handleArchiveAll?: () => void;
    status?: string;
}

const buttonConfigs: { status: StatusType; icon: JSX.Element }[] = [
    { status: 'ALL', icon: <MenuOutlined style={{ fontSize: '23px' }} /> },
    { status: 'ACTIVE', icon: <DoneRounded style={{ fontSize: '23px' }} /> },
    { status: 'PENDING_ONBOARDING', icon: <AccessTimeRounded style={{ fontSize: '23px' }} /> },
    { status: 'ARCHIVED', icon: <ArchiveOutlined style={{ fontSize: '23px' }} /> },
];

export const Header: FC<HeaderProps> = ({
    setStatus,
    setSearchTerm,
    headerTitle,
    newContactLabel,
    searchPlaceholder,
    handleNewContactClick,
    handleSearchCallback,
    setSearchParams,
    handleArchiveAll,
    status,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const handleSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
        },
        [setSearchTerm],
    );


    return (
        <>
            <Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between">
                <Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
                    <StyledTitle>{t(`${headerTitle}`)}</StyledTitle>
                </Box>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-end">
                    <StyledTextField
                        data-test="search-contacts-field"
                        type={'text'}
                        placeholder={t(`${searchPlaceholder}`)}
                        onChange={handleSearch}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ButtonV2 disabled={status === 'ARCHIVED'} onClick={() => { handleArchiveAll && handleArchiveAll() }} $variant="light2" $padding="11px">
                        <ArchiveOutlined style={{ fontSize: '23px' }} />
                    </ButtonV2>
                    <ButtonV2
                        $variant="dark"
                        onClick={handleNewContactClick}
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            gap: '8px',
                            borderRadius: '4px',
                        }}>
                        <PersonAddAltOutlined />
                        {t(`${newContactLabel}`)}
                    </ButtonV2>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <Box sx={{ backgroundColor: '#F5F8FE' }}>
                    {buttonConfigs.map(({ status: btnStatus, icon }) => (
                        <ButtonV2
                            key={btnStatus}
                            onClick={() => setStatus(btnStatus)}
                            $showActive={status === btnStatus}
                            $variant="light3"
                            $padding="11px"
                            $height="45px"
                            $width="45px"
                        >
                            {icon}
                        </ButtonV2>
                    ))}
                </Box>
            </Stack>
            <StyledParagraph>
            </StyledParagraph>
        </>
    );
};