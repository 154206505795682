import { Button, Dialog, DialogTitle, FormControlLabel, Tabs } from '@mui/material';
import styled from 'styled-components';

import { colors, T4 } from '@heylog-app/frontend-lib/app';

export const StyledFormControlLabel = styled(FormControlLabel)`
	margin-right: 0;
	padding-left: 3px;

	span {
		padding: 5px;
	}

	.MuiTypography-root {
		font-size: 14px;
	}
`;

export const StyledButton = styled(Button)`
	height: 30px;
`;

export const StyledEmailDialog = styled(Dialog)`
	& .MuiPaper-root {
		max-width: 650px;
	}
`;

export const StyledEmailDialogTitle = styled(DialogTitle)`
	background-color: ${colors.greyblue[245]};
	padding-block: 1rem;
	margin-bottom: 1rem;
`;

export const StyledDivWrapper = styled.div`
	background-color: ${colors.common.white};
	position: absolute;
	max-width: 650px;
	-webkit-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
`;
export const CustomTabs = styled(Tabs)(({ theme }) => ({
    '.MuiTabs-indicator': {
		borderRadius: '2px 2px 0px 0px',
        backgroundColor: 'var(--Primary-Main, #122CC5)',
        height: '3px',
    },
}));
export const StyledUnreadCount = styled.div`
	${T4};
	color: ${colors.common.white};
	border-radius: 50%;
	background-color: var(--Success-Main, #35C23D);
	margin-left: auto;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;