import { useEffect, type FC, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	Box,
	Chip,
	CircularProgress,
	Divider,
	Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { ConversationTypeEnum } from '@heylog-app/shared/types';
import { getMediaType } from '@heylog-app/shared/util';

import {
	StyledAccordionSummary,
	StyledInfoStack,
	StyledLabelChip,
	StyledLinkedOrderTitle,
} from './email-display.styles';
import EmailEditor from '../email-editor/email-editor';
import {
	useConversationV2,
	useConversationV2Actions,
	useConversationV2Email,
	useEmailForm,
} from '../../hooks';
import { ChooseOrder } from '../order';
import { colors } from '../../styles';
import { EmailDisplayFooter } from './email-display-footer';
import { FileCard } from '../ui';

import type { FileWithPreview } from '../attachment-button';
import type { Nullable } from '@heylog-app/shared/types';
import type { EmailDialogPayloadInterface } from '../../providers';

interface EmailDisplayPropsInterface {
	data: EmailDialogPayloadInterface;
	isShipmentPage: boolean;
	orderChipLabel: null | string | undefined;
	handleCloseDialog?: () => void;
	setSubject?: (subject: string) => void;
}

export enum EmailActionTypeEnum {
	REPLY,
	FORWARD,
}

export const EmailDisplay: FC<EmailDisplayPropsInterface> = ({
	data,
	orderChipLabel,
	setSubject,
	handleCloseDialog,
}) => {
	const { workspaceId = 0, conversationId = 0 } = data;
	const { shipmentId } = useParams();
	const { emails } = useConversationV2Email({
		workspaceId: workspaceId.toString(),
		conversationId: conversationId.toString(),
	});
	const [selectedAction, setSelectedAction] =
		useState<Nullable<EmailActionTypeEnum>>(null);

	const email = emails && emails[0];
	if (email) email.updatedAt = new Date(email.updatedAt);

	const { conversation } = useConversationV2(
		email ? email.conversationId : null,
		workspaceId.toString(),
	);

	const { linkOrderToEmailConversation, unlinkOrderFromEmailConversation } =
		useConversationV2Actions({
			id: email ? email.conversationId : null,
			workspaceId: workspaceId.toString(),
			types: [
				ConversationTypeEnum.EMAIL,
				ConversationTypeEnum.VIBER,
				ConversationTypeEnum.WHATSAPP,
			],
			shipmentId,
		});

	const emailEditorInitialData = email?.transportMessage?.html
		? `<p></p><p>${'- '.repeat(30)}</p>
    <div>From:${email?.transportMessage.from}</div>
    <div>To:${email.transportMessage.to.join(', ')}</div>
    <div>CC: ${email.transportMessage.cc}</div>
    <div>BCC: ${email.transportMessage.bcc}</div>
    <div>Subject: ${email.transportMessage.subject}</div>
    <div>Date: ${dayjs(email.updatedAt).format('DD/MM/YYYY, HH:mm')}</div>
    &gt;wrote:<blockquote>${email.transportMessage.html}</blockquote><p></p>`
		: email?.transportMessage.text;

	const {
		setAttachments,
		setAttachmentsError,
		onSubmitEmailForm,
		setValue,
		EmailFormComponent,
		isLoadingSend,
	} = useEmailForm({
		fieldsToRender: ['bcc', 'cc'],
		orderId: conversation?.orderId || undefined,
		editorInitialData: emailEditorInitialData,
		workspaceId: workspaceId.toString(),
		handleCloseDialog,
		setSubject,
	});

	useEffect(() => {
		if (email?.transportMessage.subject && setSubject) {
			setSubject(email.transportMessage.subject);
		}
	}, [email?.transportMessage.subject, setSubject]);

	if (!email || !conversation)
		return (
			<Box
				alignItems="center"
				justifyContent="center"
				display="flex"
				sx={{ minWidth: '550px', minHeight: '400px' }}
			>
				<CircularProgress color="secondary" variant="indeterminate" />
			</Box>
		);

	const attachments = email.transportMessage.attachments;

	const onNewTask = (onClose: () => void) => () => {
		onClose();
	};

	const onPrint = (onClose: () => void) => () => {
		onClose();
	};

	const onDelete = (onClose: () => void) => () => {
		onClose();
	};

	const onReply = () => {
		setSelectedAction(EmailActionTypeEnum.REPLY);

		const headers = [];
		const messageId = email.transportMessage.headers.find(
			(header) => header.name === 'Message-ID',
		);
		if (messageId) {
			const references =
				email.transportMessage.headers
					.find((header) => header.name === 'References')
					?.value.split('\n') ?? [];
			references.push(messageId.value);
			headers.push({ name: 'References', value: references.join('\n') });
			headers.push({ name: 'In-Reply-To', value: messageId?.value });
		}

		setValue('to', email.transportMessage.from);
		setValue('subject', `Re: ${email.transportMessage.subject}`);
		setValue('headers', headers);
	};

	const onForward = async () => {
		setValue('to', '');
		setValue('subject', `Fwd: ${email.transportMessage.subject}`);
		setSelectedAction(EmailActionTypeEnum.FORWARD);

		if (!attachments) return;

		const files = await Promise.all(
			attachments.map(({ publicUrl, name, type }) =>
				fetchAndCreateFile(publicUrl || '', name || '', type),
			),
		);
		setAttachments(files.filter(Boolean) as FileWithPreview[]);
	};

	return (
		<Stack justifyContent="space-between" height="100%">
			{selectedAction === null && (
				<Box width="100%">
					<Accordion sx={{ backgroundColor: colors.grey[200], boxShadow: 'unset' }}>
						<StyledAccordionSummary
							expandIcon={<GridExpandMoreIcon />}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							{email?.transportMessage.subject}
						</StyledAccordionSummary>
						<AccordionDetails sx={{ padding: 0 }}>
							<Divider />
							<Stack paddingInline={2}>
								<Stack direction="row" gap={4}>
									<Stack flex="1 1 50%" overflow="hidden">
										<StyledInfoStack gap={2} direction="row">
											<div>From:</div>
											<div>{email.transportMessage.from}</div>
										</StyledInfoStack>
										<StyledInfoStack gap={2} direction="row">
											<div>CC:</div>
											<div>{email.transportMessage.cc?.join(', ')}</div>
										</StyledInfoStack>
										<StyledInfoStack gap={2} direction="row">
											<div>Date:</div>
											<div>{dayjs(email.updatedAt).format('DD/MM/YYYY, HH:mm')}</div>
										</StyledInfoStack>
									</Stack>
									<Stack flex="1 1 50%" overflow="hidden">
										<StyledInfoStack gap={2} direction="row">
											<div>To:</div>
											<div>{email.transportMessage.to.join(', ')}</div>
										</StyledInfoStack>
										<StyledInfoStack gap={2} direction="row">
											<div>BCC:</div>
											<div>{email.transportMessage.bcc?.join(', ')}</div>
										</StyledInfoStack>
										<StyledInfoStack gap={2} direction="row">
											<div>Labels:</div>
											<Stack direction="row" gap={1} flexWrap="wrap">
												{conversation.labels.map((label, index) => (
													<div key={label.id}>
														<StyledLabelChip key={index} label={label.value} />
													</div>
												))}
											</Stack>
										</StyledInfoStack>
									</Stack>
								</Stack>
								<Stack paddingBlock={2} gap={1}>
									<StyledLinkedOrderTitle>Linked order</StyledLinkedOrderTitle>
									<Stack gap={2}>
										<Stack direction="row" flexWrap="wrap" gap={1}>
											{orderChipLabel && (
												<Chip
													key={'chip-order-' + conversation.orderReference}
													label={orderChipLabel}
													onDelete={unlinkOrderFromEmailConversation}
												/>
											)}
										</Stack>
										<ChooseOrder
											flexWrap="nowrap"
											width="100%"
											handleAssign={linkOrderToEmailConversation}
										/>
									</Stack>
								</Stack>
							</Stack>
						</AccordionDetails>
					</Accordion>
					<Box sx={{ marginTop: 2 }}>
						<EmailEditor
							hideToolbar
							hideBorder={false}
							readOnly={true}
							// fall back to plain text if message has no HTML content
							initialData={email.transportMessage.html ?? email.transportMessage.text}
							attachments={email.transportMessage.attachments ?? []}
						/>
					</Box>

					{attachments ? (
						<Stack sx={{ marginBlock: 2 }} gap={2} direction="row" flexWrap="wrap">
							{attachments.map((file) => (
								<a
									key={file.id}
									href={file.publicUrl}
									download
									style={{ textDecoration: 'none' }}
								>
									<FileCard
										width={128}
										height={88}
										fileType={file.type}
										mediaType={getMediaType(file.type)}
										fileName={file.name || ''}
										src={file.publicUrl || ''}
										size={file.size}
									/>
								</a>
							))}
						</Stack>
					) : null}
				</Box>
			)}

			{selectedAction !== null && (
				<Box marginBlock={1}>
					<Stack gap={1} paddingTop={0}>
						{EmailFormComponent}
					</Stack>
				</Box>
			)}
			<Box bgcolor={colors.common.white} position="sticky" zIndex={10} bottom="0px">
				<EmailDisplayFooter
					onReply={onReply}
					onForward={onForward}
					onNewTask={onNewTask}
					onDelete={onDelete}
					onPrint={onPrint}
					setAttachments={setAttachments}
					setAttachmentsError={setAttachmentsError}
					selectedAction={selectedAction}
					isLoadingSend={isLoadingSend}
					onSend={onSubmitEmailForm}
				/>
			</Box>
		</Stack>
	);
};

const fetchAndCreateFile = async (url: string, fileName: string, mimeType: string) => {
	try {
		// Fetch the file content
		const response = await fetch(url);
		const fileContent = await response.blob();

		// Create a new File object
		const file = new File([fileContent], fileName, {
			type: mimeType || response.headers.get('content-type') || undefined,
		}) as FileWithPreview;
		file.src = URL.createObjectURL(file);
		// Return the File object
		return file;
	} catch (error) {
		console.error('Error fetching file:', error);
		return null;
	}
};
