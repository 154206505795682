import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { useApiClientContext } from './use-api-client-context.hook';
import { getFetcher, getUsersKey, getUsersKeyForChat } from '../util';
import { AbilityContext } from '../providers';

import type { UserResInterface } from '@heylog-app/shared/types';

export const useUsers = (workspaceId: string, currentUser?: UserResInterface) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, isLoading } = useSWR<Array<UserResInterface>>(
		ability.can(Actions.MANAGE, 'Workspace') ? getUsersKey(workspaceId) : null,
		getFetcher(apiClient),
	);

	if (currentUser !== undefined) {
		data?.sort((a: UserResInterface, b: UserResInterface) => {
			return a.id === currentUser.id ? -1 : b.id === currentUser.id ? 1 : 0;
		});
	}

	return {
		users: data,
		isLoading,
		isError: error,
	};
};

export const useUsersForChat = (workspaceId: string,) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, isLoading } = useSWR<Array<UserResInterface>>(
		ability.can(Actions.MANAGE, 'Workspace') ? getUsersKeyForChat(workspaceId) : null,
		getFetcher(apiClient),
	);



	return {
		users: data,
		isLoading,
		isError: error,
	};
};
