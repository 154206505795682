import type { AxiosInstance } from 'axios';

export const getFetcher =
	(apiClient: AxiosInstance) =>
		<T>(key: string) =>
			apiClient
				.get<T>(key)
				.then((res) => res.data)
				.catch(async (error) => {
					throw error;
				});


export const putFetcher =
	(apiClient: AxiosInstance) =>
		<T>(key: string) =>
			apiClient
				.put<T>(key)
				.then((res) => res.data)
				.catch(async (error) => {
					throw error;
				});
