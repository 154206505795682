import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { useContactName } from '../../../hooks/use-contact-name';

import type { FC } from 'react';
import type { FullContactResInterface } from '@heylog-app/shared/types';

const StyledContactName = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ContactName: FC<{ contact: FullContactResInterface }> = ({ contact }) => {
	const { firstName, lastName } = contact;
	const contactName = useContactName({ firstName, lastName });
	console.log('contact===========>', contact)
	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!textElementRef.current) return;
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
		);
	}, []);

	return (
		<Tooltip title={contactName} disableHoverListener={!isOverflowed}>
			<StyledContactName ref={textElementRef}>{contactName}</StyledContactName>
		</Tooltip>
	);
};
