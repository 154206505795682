export const mutateMessagesKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations/${conversationId}/messages`;
};
export const mutateMediaMessagesKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations/${conversationId}/media`;
};
export const mutateHeylogMediaMessagesKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations/${conversationId}/media-internal`;
};
export const mutateHeylogMessagesKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations-v2/${conversationId}/user-to-user/chat`;
};