import { Button, colors, DialogContent, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
	ContactRoleEnum,
	deeplSupportedLanguages,
	googleSupportedLanguages,
	TranslationServiceEnum,
} from '@heylog-app/shared/types';
import { getRoleIcon, phoneRegex, roles } from '@heylog-app/shared/util';

import { StyledDialogActions, StyledRoleIcon } from './edit-contact-form.styles';
import { useContactActions, useWorkspace } from '../../../hooks';
import { formatPhoneNumberForDisplay, preventSubmitOnEnter } from '../../../util';
import { ControlledSelect } from '../../ui';

import type {
	FullContactResInterface,
	UpdateContactReqInterface,
} from '@heylog-app/shared/types';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';

interface DisplayFormData extends UpdateContactReqInterface {
	phone?: string;
}

type FormData = DisplayFormData;

type EditContactFormProps = {
	contact?: FullContactResInterface; // provide ID if editing a contact
	onSuccess: () => void;
	onCancel: () => void;
};

export const EditContactForm: FC<EditContactFormProps> = ({
	contact,
	onSuccess,
	onCancel,
}) => {
	const { workspaceId = '' } = useParams();
	const { updateContact } = useContactActions(contact?.id.toString());
	const { workspace } = useWorkspace(workspaceId);

	const { t } = useTranslation();

	const supportedLanguages =
		workspace?.translationService === TranslationServiceEnum.DEEPL
			? deeplSupportedLanguages
			: googleSupportedLanguages;

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm<FormData>({
		defaultValues: {
			firstName: contact?.firstName || '',
			lastName: contact?.lastName || '',
			licensePlate: contact?.licensePlate || '',
			company: contact?.company || '',
			phone: formatPhoneNumberForDisplay(contact?.phone) || '',
			email: contact?.email || '',
		},
	});

	useEffect(() => {
		if (contact) {
			reset({
				role: contact.role || ContactRoleEnum.NONE,
				preferredLanguage: contact.preferredLanguage,
			});
		}
	}, [contact, reset]);

	const onSubmit = async (data: FormData) => {
		if (contact) {
			const res = await updateContact({
				id: contact?.id.toString(),
				data: {
					...data,
					email: data.email?.toLowerCase(),
					isPhoneHide: contact.isPhoneHide,
				},
				workspaceId,
			});

			if (res) {
				onSuccess();
			}
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventSubmitOnEnter} noValidate>
			<DialogContent>
				<Controller
					control={control}
					name="firstName"
					render={({ field }) => (
						<TextField
							data-test="edit-contact-first-name"
							label={t('contacts.form.labels.firstName')}
							fullWidth
							margin="dense"
							{...register('firstName')}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="lastName"
					render={({ field }) => (
						<TextField
							label={t('contacts.form.labels.lastName')}
							data-test="edit-contact-last-name"
							fullWidth
							margin="dense"
							{...register('lastName')}
							{...field}
						/>
					)}
				/>

				<ControlledSelect
					id="role"
					label="contacts.form.labels.role"
					data-test="edit-contact-role"
					control={control as unknown as Control<FieldValues>}
				>
					{roles.map((role) => {
						const roleIcon = getRoleIcon(role.value);
						return (
							<MenuItem
								data-test={'roleOption-' + role.value}
								key={'roleOption-' + role.value}
								value={role.value}
							>
								{roleIcon && (
									<StyledRoleIcon
										path={roleIcon}
										size={'1em'}
										color={colors.common.black}
									/>
								)}
								{t(`roles.${role.value}`)}
							</MenuItem>
						);
					})}
				</ControlledSelect>

				<Controller
					control={control}
					name="licensePlate"
					render={({ field }) => (
						<TextField
							label={t('contacts.form.labels.licensePlate')}
							data-test="edit-licensePlate-contact"
							fullWidth
							margin="dense"
							{...register('licensePlate')}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="company"
					render={({ field }) => (
						<TextField
							label={t('contacts.form.labels.company')}
							data-test="edit-contact-company"
							fullWidth
							margin="dense"
							{...register('company')}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="phone"
					render={({ field }) => (
						<TextField
							label={t('contacts.form.labels.phone')}
							fullWidth
							disabled
							margin="dense"
							placeholder={'+43660...'}
							error={errors.phone ? true : false}
							helperText={errors.phone?.message && t(errors.phone?.message)}
							{...register('phone', {
								pattern: phoneRegex,
								disabled: true,
								validate: (value) => {
									// prevent deleting phone number if there has been one to avoid errors in conversations
									if (contact?.phone && value?.length === 0) {
										return 'forms.errors.required';
									}
									return true;
								},
							})}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="email"
					render={({ field }) => (
						<TextField
							label={t('contacts.form.labels.email')}
							data-test="edit-contact-email"
							fullWidth
							margin="dense"
							{...register('email')}
							{...field}
						/>
					)}
				/>

				<ControlledSelect
					id="preferredLanguage"
					label="contacts.form.labels.preferredLanguage"
					data-test="edit-contact-preferred-language"
					control={control as unknown as Control<FieldValues>}
				>
					{supportedLanguages.map((language) => (
						<MenuItem
							data-test={'preferredLangOption-' + language}
							key={'preferredLangOption-' + language}
							value={language}
						>
							{t(`languages.${language}`)}
						</MenuItem>
					))}
				</ControlledSelect>
			</DialogContent>

			<StyledDialogActions>
				<Button variant="outlined" type="reset" onClick={onCancel}>
					{t('actionLabels.cancel')}
				</Button>

				<Button data-test="confirm-edit-contact-button" variant="contained" type="submit">
					{contact ? t('actionLabels.save') : t('contacts.form.labels.submit')}
				</Button>
			</StyledDialogActions>
		</form>
	);
};
