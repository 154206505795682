import { useTranslation } from 'react-i18next';
import { Avatar, Box, Checkbox, Chip, IconButton, Stack, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccount, mdiBookmark, mdiBookmarkOutline, mdiInformationOutline } from '@mdi/js';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { getFormattedEta, getNameInitials } from '../util';
import { useConversationsV2 } from './use-conversationv2.hook';
import { colors } from '../styles';
import { useOrders } from './use-orders.hook';
import { convertOrderToDashboardRow } from './use-dashboard.hook';

import type { ContactRoleType, ContactStatusTypeV2, ConversationType, Nullable } from '@heylog-app/shared/types';
import type { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { ButtonV2 } from '../components';
import styled from 'styled-components';
import { StyledAvatar } from '../components/ui/message-author/message-author.styles';
import { EditOutlined } from '@mui/icons-material';
import { useContacts } from './use-contacts.hooks';


export interface ContactsDetailsRow {
    contactId?: number;
    fullName?: string;
    company?: string;
    email?: string;
    contactNumber?: string;
    role?: ContactRoleType;
    preferredLanguage?: Nullable<string>;
    isPhoneHide?: boolean;
}
type PaginationModel = {
    pageNumber?: number;
    pageSize?: number;
};

export const useContactsData = (handleEditClick?: (contact: ContactsDetailsRow) => void, debouncedSearchTerm?: string, paginationModel?: PaginationModel, status?: ContactStatusTypeV2) => {
    const { t } = useTranslation();
    const columns: GridColDef[] = [
        {
            field: 'fullName',
            minWidth: 200,
            headerName: t('contacts.header.fullName'),
            headerClassName: 'bold-header',
            renderCell: (params) => {
                if (!params.row.firstName  && !params.row.lastName) return null;
                return (
                    <Stack direction="row" alignItems="center">
                        <StyledAvatar
                            sx={{
                                height: '22px',
                                width: '22px',
                                fontSize: '12px',
                            }}
                        >
                            {getNameInitials(params.row.firstName +' '+ params.row.lastName)}
                        </StyledAvatar>
                        <Stack sx={{ marginLeft: '8px' }}>
                            <Typography>
                                {params.row.firstName + params.row.lastName}
                            </Typography>
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            field: 'company',
            minWidth: 230,
            headerClassName: 'bold-header',
            headerName: t(`contacts.header.company`),
        },
        {
            field: 'email',
            minWidth: 300,
            headerName: t(`contacts.header.email`),
            headerClassName: 'bold-header',
        },
        {
            field: 'contactNumber',
            minWidth: 200,
            headerName: t(`contacts.header.contactNumber`),
            headerClassName: 'bold-header',
            renderCell: (params) => (params.row.isPhoneHide ? '************' : params.value),
        },
        {
            field: 'role',
            minWidth: 100,
            headerName: t(`contacts.header.role`),
            headerClassName: 'bold-header',
        },
        {
            field: 'actions',
            headerName: t(`actionLabels.actions`),
            headerClassName: 'bold-header',
            sortable: false,
            filterable: false,
            minWidth: 100,
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleEditClick?.(params.row)}
                    aria-label="edit"
                >
                    <EditOutlined />
                </IconButton>
            ),
        },
    ];

    const {
        contacts,
        isLoading,
        updateContacts,
        pagination,
    } = useContacts({ q: debouncedSearchTerm, page: paginationModel?.pageNumber, rowsPerPage: paginationModel?.pageSize, status: status });

    let rows: ContactsDetailsRow[] = contacts?.map((contact) => ({
        contactId: contact.id ?? undefined,
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        fullName: `${contact.firstName || ''} ${contact.lastName || ''}`.trim(),
        company: contact.company || '-',
        email: contact.email || '-',
        contactNumber: (!contact.isPhoneHide ? contact.phone : "**********") ?? "-",
        role: contact.role || '-',
        preferredLanguage: contact.preferredLanguage || null,
        isPhoneHide: contact.isPhoneHide || false,
    })) || [];

    return { columns, rows, isLoading, updateContacts, totalRowsContact: pagination?.totalRows };
};
