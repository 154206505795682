import React from "react";
import { OverLayText, StyledGridOverlay } from "./no-results-overlay.styles";
import myImage from '../../assets/empty_561840338 1.jpg';
import { GridOverlay } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";

interface CustomNoResultsOverlayProps {
    message?: string;
}

export const CustomNoResultsOverlay = (props: CustomNoResultsOverlayProps) => {
    const { t } = useTranslation();
    const { message = t('actionLabels.noResultsFound') } = props;
    return (
        <StyledGridOverlay as={GridOverlay}>
            <img src={myImage} alt="Empty" />
            <OverLayText>{message}</OverLayText>
        </StyledGridOverlay>
    );
};
