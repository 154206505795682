import { Avatar, Grid, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

import { colors, SIZE_MD, STYLE_1, STYLE_2 } from "../../../styles";
import { ButtonV2, DialogFooter } from "../../ui";
import Image from "../../../assets/avatar-bg@2x.jpg";

export const StyledInputDescription = styled.span`
  ${STYLE_2};
  margin-bottom: 1rem;
  display: block;
`;

export const StyledDecoration = styled.div`
  height: 1px;
  margin-left: 3rem;
  background-color: ${colors.greyblue[205]};
`;

export const StyledDialogFooter = styled(DialogFooter)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 5px;
  border-top: none;
  position: relative;
`;

export const StyledDialogFooter2 = styled(DialogFooter)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 5px;
  border-top: none;
  position: relative;
`;

export const StyledTab = styled(Tab)`
  text-transform: none;
  color: var(--Primary-Darker, #030b5e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
`;

export const StyledMessageGroupWrapper = styled.div``;

export const StyledTabsContainer = styled.div`
  margin-top: 1rem;
  padding: 0 3rem;
`;

export const StyledDialogContainer = styled.div`
  min-height: 480px;
`;

export const StyledQRCodePlaceholder = styled.div`
  height: 150px;
  width: 150px;
  background-color: ${colors.greyblue[225]};
`;

export const StyledDownloadButton = styled.button`
  ${STYLE_1}
  ${SIZE_MD}

	border: none;
  margin: 1rem 0 0 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  transition: all linear 300ms;

  background: transparent;

  &:hover {
    color: ${colors.primary.main};
  }

  svg {
    margin-bottom: -2px;
  }
`;
export const StyledDownloadButton2 = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`;
export const StyledTabs = styled(Tabs)`
  margin-top: 10px;
`;
export const ButtonsGridContainer = styled(Grid)`
  margin: 2rem 3rem;
  float: inline-end;
`;
export const StyledAvatar = styled(Avatar)`
  background-image: url(${Image});
  background-size: cover;
`;
export const StyledDiv = styled.div`
  position: absolute;
  top: 11px;
  left: 14px;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--Primary-Main, #122cc5);
`;
export const StyledButtonV2 = styled(ButtonV2)`
  height: 40px;
  padding: 5px 25px;
  border-radius: 4px;
  background: var(--Primary-Main, #122CC5);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
`;
