import { Button } from "@mui/material";
import styled, { css } from "styled-components";

import { colors } from "../../../styles";
import { T2_medium, T3_medium } from "../../../styles/theme/typography-v2";

import type { ButtonV2Props } from "./buttonV2";

export const StyledButton = styled(Button)<ButtonV2Props>`
  padding: ${({ $padding }) => ($padding ? $padding : "12px 28px")};
  border-radius: ${({ $borderRadius }) => ($borderRadius ? $borderRadius : "4px")};
  box-shadow: unset;
  ${({ $typography }) => ($typography === "T2" ? T2_medium : T3_medium)}
  min-width: ${({ $minWidth }) => $minWidth || "fit-content"};
  height: ${({ $height }) => $height || "fit-content"};
  width: ${({ $width }) => $width || ""};
  position: relative;

  svg path {
    fill: currentcolor;
  }

  &:hover {
    box-shadow: unset;
  }

  ${({ $variant, $showActive }) => {
    if ($variant === "dark")
      return css`
        background-color: ${colors.primary.main};
        color: ${colors.common.white};

        &:hover {
          background-color: ${colors.primary.dark};
        }
      `;
    if ($variant === "light")
      return css`
        background-color: ${colors.grey[200]};
        color: ${colors.primary.main};

        &:hover {
          border: 1px solid ${colors.primary.main} !important;
        }
      `;
    if ($variant === "light2" && $showActive)
      return css`
        background-color: ${colors.primary.lighter};
        color: ${colors.primary.main};
        border: 1px solid ${colors.primary.main};
      `;
    if ($variant === "light2")
      return css`
        background-color: ${colors.grey[200]};
        color: ${colors.primary.main};

        &:hover {
          border: 1px solid ${colors.primary.main};
          background-color: ${colors.primary.lighter} !important;
        }
      `;
    if ($variant === "light3" && $showActive)
      return css`
        background-color: ${colors.primary.lighter};
        color: ${colors.primary.main};
        border: 1px solid ${colors.primary.main};

        &:hover {
          background-color: ${colors.primary
            .lighter} !important; /* Keep same color on hover */
        }
      `;

    if ($variant === "light3")
      return css`
        background-color: ${colors.grey[200]};
        color: ${colors.primary.main};

        &:hover {
          border: 1px solid ${colors.primary.main};
          background-color: ${colors.primary.lighter} !important;
        }
      `;
    if ($variant === "warning")
      return css`
        background-color: ${colors.warning.light};
        color: ${colors.warning.dark};

        &:hover {
          background-color: ${colors.warning.light} !important;
        }
      `;
    if ($variant === "warningWhite")
      return css`
        background-color: white;
        color: ${colors.warning.dark};

        &:hover {
          background-color: ${colors.warning.light} !important;
        }
      `;
    if ($variant === "error")
      return css`
        background-color: ${colors.error.light};
        color: ${colors.error.dark};

        &:hover {
          background-color: ${colors.error.light} !important;
        }
      `;
    if ($variant === "outlined")
      return css`
        background-color: white;
        color: #122cc5;
        border: 2px solid #122cc5;

        &:hover {
          backgroud-color: white !important;
        }
      `;
    if ($variant === "outlined2")
      return css`
        background-color: white;
        color: #122cc5;
        border: 1px solid transparent;

        &:hover {
          border-color: ${colors.primary.main};
          background-color: ${colors.primary.lighter} !important;
        }
      `;
    return null;
  }}
`;
