import {
  Button,
  Chip,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import styled from "styled-components";

import { colors, H2, SIZE_MD, STYLE_3, T2 } from "@heylog-app/frontend-lib/app";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  position: relative;
  padding: 0 18px 10px 18px;
  margin-top: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

export const StyledToggleButton = styled(ToggleButton)`
  background-color: none;
  z-index: 1;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.lighter};
  }
`;

export const StyledChip = styled(Chip)`
  position: absolute;
  top: -15px;
  right: 33%;
  z-index: 2;
`;

export const StyledInviteButton = styled(Button)`
  width: 7rem;
  height: 2.1rem;
`;

export const StyledSidebarDescription = styled.div``;

export const StyledSidebarButton = styled(Button)`
  width: 100%;
`;

export const StyledGuestList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWelcomeContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const StyledDetailsTitle = styled.h1`
  ${H2};
  color: ${colors.grey[900]};
  white-space: nowrap;
  margin: 0;
`;
export const StyledLinkedOrderChip = styled(Chip)(({ theme }) => ({
  borderRadius: "4px",
  border: `0px`,
  backgroundColor: "var(--White-300, #E1E4EA)",
  color: "var(--Primary-Darker, #030B5E)",
  "& .MuiChip-deleteIcon": {
    color: "#7C839E",
  },
}));
export const CustomTabs = styled(Tabs)(({ theme }) => ({
  ".MuiTabs-indicator": {
    borderRadius: "2px 2px 0px 0px",
    backgroundColor: "var(--Primary-Main, #122CC5)",
    height: "3px",
  },
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  color: "var(--Primary-Darker, #030B5E)",
  textTransform: "unset !important",
  ...T2,
}));
export const StyledContactName = styled.div`
  ${STYLE_3};
  ${SIZE_MD};
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
