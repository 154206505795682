import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mdi/react';
import { mdiAccountPlusOutline } from '@mdi/js';

import {
	type ConversationInvitationResInterface,
	OrderConnectionStatusEnum,
	type OrderResInterface,
} from '@heylog-app/shared/types';
import {
	archiveGuestAPI,
	ButtonV2,
	ConfirmArchiveDialog,
	createOrderConnectionApi,
	InviteGuestUserDialog,
	ROUTES,
	Sidebar,
	SidebarContent,
	SidebarList,
	useApiClientContext,
	useContacts,
	useDialog,
	useGuestInvitations,
	useGuests,
	useSnackbar,
	useOrderActions,
	FlashSnackbar,
	archiveGuestInvitationAPI,
	useOrders,
	OrderSelectField,
} from '@heylog-app/frontend-lib/app';

import { StyledGuestList, StyledSidebarDescription } from './contacts.styles';
import { GuestUser } from './components';
import { GuestInvitation } from './components/guest-invitation';

import type { FC } from 'react';

interface OrderOption {
	id: number;
	label: string;
}

export const ContactManagementPage: FC = () => {
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '', contactId, conversationId } = params;
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [pendingInvitationsToDelete, setpendingInvitationsToDelete] = useState('');
	const [guestUserToDelete, setGuestUserToDelete] = useState('');
	const { apiClient } = useApiClientContext();
	const navigate = useNavigate();
	const { contacts, updateContacts } = useContacts({ q: '' });
	const hasContacts = contacts?.length && contacts?.length > 0 ? true : false;
	const { guests, fetchGuests } = useGuests(
		workspaceId ? workspaceId : '-1',
		conversationId ? conversationId : '-1',
	);
	
	const { guestInvitations, fetchGuestInvitations } = useGuestInvitations(
		workspaceId ? workspaceId : '-1',
		conversationId ? conversationId : '-1',
	);
	
	const currentContact = useMemo(() => {
		if (!contactId) return null;
		return contacts?.find((contact) => contact.id === parseInt(contactId));
	}, [contacts, contactId]);
	
	const currentAssignment = useMemo(
		() =>
			currentContact?.orderConnections?.find((connection) =>
				connection.status !== OrderConnectionStatusEnum.ARCHIVED ? true : false,
	),
	[currentContact],
);
const handleInviteSuccess = () => {
		fetchGuestInvitations();
		fetchGuests();
	};
	const { order: currentOrder } = useOrderActions(
		currentAssignment?.orderId ? currentAssignment.orderId + '' : undefined,
	);
	const [selectedOrder, setSelectedOrder] = useState<OrderResInterface | undefined>(
		currentOrder,
	);
	useEffect(() => {
		setSelectedOrder(currentOrder)
	}, [currentOrder])
	const { orders } = useOrders();
	const options: OrderOption[] =
		orders?.map((order) => {
			return {
				id: order.id,
				label:
					order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
						?.value || order.refNumber,
			};
		}) || [];

	const handleNavigateToOrder = useCallback(() => {
		navigate(
			generatePath(ROUTES.ORDERS.CHAT, {
				conversationId: currentContact?.conversations[0]?.id.toString(),
				workspaceId: currentOrder?.workspaceId?.toString(),
				orderId: currentOrder?.id.toString(),
				contactId: currentContact?.id.toString(),
			}),
		);
	}, [currentOrder, navigate, currentContact]);

	const onSelectOrder = useCallback(
		async (orderp: OrderResInterface) => {
			if (workspaceId && contactId) {
				await createOrderConnectionApi(apiClient, {
					workspaceId,
					data: { orderId: orderp?.id, contactId: parseInt(contactId) },
				});
				updateContacts();
			}
		},
		[workspaceId, contactId, apiClient, updateContacts],
	);
	const pendingUserInvitations = guestInvitations?.filter(
		(guestInvitation) => guestInvitation.status === 'PENDING',
	);
	const handleRemovePendingInviteClick = (email: string) => {
		setpendingInvitationsToDelete(email);
		openConfirmArchivePendingInvitationDialog();
	};

	const handleRemoveGuest = (guestId: number) => {
		setGuestUserToDelete(guestId.toString());
		openConfirmArchiveGuestUserDialog();
	};

	const {
		showDialog: showInviteDialog,
		openDialog: openInviteDialog,
		closeDialog: closeInviteDialog,
	} = useDialog();

	const handleArchivePendingInvitations = async () => {
		if (!workspaceId || !conversationId || !pendingInvitationsToDelete) return;

		await archiveGuestInvitationAPI(apiClient, {
			data: {
				email: pendingInvitationsToDelete,
			},
			workspaceId,
			conversationId,
		})
			.then(() => {
				openSnackbar('success', t('settings.archiveInvitationDialog.success'));
				fetchGuestInvitations();
				closeConfirmArchivePendingInvitationDialog();
			})
			.catch((e) => console.log(e))
			.finally(() => setpendingInvitationsToDelete(''));
	};

	const handleArchiveGuestUser = async () => {
		if (!workspaceId || !conversationId || !guestUserToDelete) return;

		await archiveGuestAPI(apiClient, {
			workspaceId,
			conversationId,
			guestToRemoveId: guestUserToDelete,
		})
			.then(() => {
				openSnackbar('success', t('settings.archiveGuestUserDialog.success'));
				fetchGuests();
				closeConfirmArchiveGuestUserDialog();
			})
			.catch((e) => console.log(e))
			.finally(() => setGuestUserToDelete(''));
	};

	const {
		showDialog: showConfirmArchivePendingInvitationDialog,
		openDialog: openConfirmArchivePendingInvitationDialog,
		closeDialog: closeConfirmArchivePendingInvitationDialog,
	} = useDialog();

	const {
		showDialog: showConfirmArchiveGuestUserDialog,
		openDialog: openConfirmArchiveGuestUserDialog,
		closeDialog: closeConfirmArchiveGuestUserDialog,
	} = useDialog();

	// Deduplicate pending user invitations and remove the ones that are already workspace users
	let uniquePendingGuestInvitations: ConversationInvitationResInterface[] = [];
	if (pendingUserInvitations?.length && guests) {
		const acceptedGuestEmailAddresses = guests?.map((guest) => guest.email);

		uniquePendingGuestInvitations = pendingUserInvitations
			.filter((user, index) => {
				const userSameEmail = pendingUserInvitations.find(
					(invitation) =>
						invitation.email === user.email && invitation.createdAt > user.createdAt,
				);

				const userSameEmailIndex = pendingUserInvitations.findIndex(
					(invitation) => invitation === userSameEmail,
				);

				return !userSameEmail || userSameEmailIndex === index;
			})
			.filter(
				(guestInvitation) => !acceptedGuestEmailAddresses.includes(guestInvitation.email),
			);
	}

	return (
		<Box sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<Sidebar dataTest="contact-sidebar" variant="secondary" width="80%">
				<SidebarContent title="contacts.toolSidebar.assignedOrder">
					{t('contacts.assignOrderMsg')}
					{options[0] && (
						<OrderSelectField
							selectedProp={currentOrder}
							confirmationMessage={t('contacts.toolSidebar.assignOrderConfirmation')}
							shouldConfirm={!!currentAssignment}
							onSelect={onSelectOrder}
							handleNavigation={handleNavigateToOrder}
							setSelectedOrder={setSelectedOrder}
							selectedOrder={selectedOrder}
						/>
					)}
				</SidebarContent>

				<Divider />

				{hasContacts && contactId && (
					<SidebarList>
						<SidebarContent title={t('contacts.toolSidebar.inviteGuestTitle')}>
							<StyledSidebarDescription>
								{t('contacts.toolSidebar.inviteGuestDescription')}
							</StyledSidebarDescription>

							<StyledGuestList>
								{guests?.map((guest) => (
									<GuestUser
										key={guest.id}
										onClick={() => handleRemoveGuest(guest.id)}
										firstName={guest.firstName}
										lastName={guest.lastName}
										email={guest.email}
										guest
									/>
								))}
								{uniquePendingGuestInvitations?.map((guestInvitation) => (
									<GuestInvitation
										key={guestInvitation.id}
										email={guestInvitation.email}
										onClick={() => handleRemovePendingInviteClick(guestInvitation.email)}
										invitationDate={guestInvitation.createdAt}
									/>
								))}
							</StyledGuestList>
							<Stack
								sx={{
									width: '40%',
								}}
							>
								<ButtonV2
									data-test="invite-guest-button"
									$variant="light3"
									onClick={openInviteDialog}
									startIcon={<Icon path={mdiAccountPlusOutline} size={'1.5em'} />}
								>
									{t('contacts.toolSidebar.inviteGuestButton')}
								</ButtonV2>
							</Stack>
						</SidebarContent>
					</SidebarList>
				)}
			</Sidebar>

			<InviteGuestUserDialog
				onClose={closeInviteDialog}
				isOpen={showInviteDialog}
				handleInviteSuccess={handleInviteSuccess}
			/>

			<ConfirmArchiveDialog
				dataTest="archive-pending-guest-invitation-dialog"
				isOpen={showConfirmArchivePendingInvitationDialog}
				onClose={closeConfirmArchivePendingInvitationDialog}
				dialogTitle={t('settings.archiveInvitationDialog.title')}
				dialogContent={t('settings.archiveInvitationDialog.body')}
				onArchive={handleArchivePendingInvitations}
			/>

			<ConfirmArchiveDialog
				dataTest="archive-guest-user-dialog"
				isOpen={showConfirmArchiveGuestUserDialog}
				onClose={closeConfirmArchiveGuestUserDialog}
				dialogTitle={t('settings.archiveGuestUserDialog.title')}
				dialogContent={t('settings.archiveGuestUserDialog.body')}
				onArchive={handleArchiveGuestUser}
			/>
		</Box>
	);
};
