import { SnackbarOrigin } from '@mui/material';
import type { AlertColor } from '@mui/material/Alert/Alert';

export type UseSnackbarStatusType = {
	open: boolean;
	severity: AlertColor;
	message: string;
	autoClose: boolean;
	autoCloseDuration: number;
	anchorOrigin: SnackbarOrigin;
};

export type UseSnackbarReturnType = [
	state: UseSnackbarStatusType,
	open: (
		severity: AlertColor,
		message: string,
		autoClose?: boolean,
		autoCloseDuration?: number,
		anchorOrigin?: SnackbarOrigin,
	) => void,
	close: () => void,
];
